<!--suppress ALL -->
<template>
  <div class="wrapper sponsor-content-article">
    <row>
      <column xs="12">
        <div class="d-flex flex-column d-md-block clearfix">

          <!-- Image, title and leadtext -->
          <div class="article-top-section order-2 mb-md-3 mb-lg-5">
            <article-lead-asset :sizes="$store.state.publication.leadImageSizes.sponsoredArticle"/>
            <div class="title-and-leadtext">
              <article-title class="ff-flama text-center pl-2 pr-2 pl-sm-3 pr-sm-3 pb-3 pb-sm-6 pb-md-2 pb-lg-5 m-0" />
              <article-lead-text class="ff-flama fs-xl fw-light text-center mt-3 mt-sm-4 mt-md-0 mb-4 mb-md-3 mb-lg-6" />
            </div>
          </div>

          <!-- Logo and company name -->
          <div class="ff-flama order-1 company d-flex d-md-block">
            <div class="company-logo mr-3 mr-md-0 mb-3 mb-md-4">
              <a
                :href="company.linkUrl"
                target="_blank"
                rel="noopener">
                <img
                  :src="company.logo"
                  width="100%">
              </a>
            </div>
            <div
              v-text="__('sponsor.content.from', company.name)"
              class="fs-lg fw-medium lh-22 tt-uppercase mb-3 mb-md-4 ml-3 ml-md-0 company-name"></div>
          </div>

          <!-- Byline -->
          <div class="ff-flama order-3 byline pt-2 pt-md-0 pb-3 pb-md-0 mb-4 mb-md-0">
            <div class="author-info">
              <div class="fs-lg fw-medium lh-22">{{ __('written.by') }}</div>
              <div v-text="company.byline"></div>
              <div
                v-text="company.representative"
                class="st-italic mb-2"></div>
              <div class="td-underline">
                <a
                  :href="company.linkUrl"
                  v-html="company.link"
                  target="_blank"
                  rel="noopener"></a>
              </div>
            </div>
            <div class="fs-sm fw-medium published pt-3 pt-md-0">
              {{ __('published') }}
            </div>
            <article-date :date="publishedAt"/>
          </div>

          <!-- Body -->
          <div class="order-4 article-center-column">
            <article-body class="article-body ff-flama fs-lg clearfix"/>
            <div
              v-if="callToAction"
              class="clear-both">
              <div
                v-html="callToAction"
                class="btn btn-success btn-lg mb-3 call-to-action"></div>
            </div>
          </div>

        </div>
      </column>

    </row>
  </div>
</template>
<script>
import { Page, ArticleComponents } from 'global-components';
import { extractTextFromHtml } from 'global-utils';

export default {
  name: 'sponsored-article-page',
  dataResolver: 'sponsoredArticlePage',
  components: {
    ...ArticleComponents,
  },
  extends: Page,
  data() {
    const articleData = this.$store.state.article;
    const companyData = articleData.company || {};
    const linkUrl = companyData.link ? extractTextFromHtml(companyData.link) : '';
    companyData.linkUrl = linkUrl.startsWith('http') ? linkUrl : `//${linkUrl}`;
    articleData.company = companyData;
    return articleData;
  }
};
</script>
